import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Fade from 'react-reveal/Fade';
import Head from '../components/head';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Footer from 'components/footer';
import {
  Container,
  ImgContainer,
  TextIntro,
  TextProject,
  ProjectImg,
} from './projects-template.css';

export default function Template({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Head pageTitle={`Work - ${post.frontmatter.title}`} />
      <Container>
        <ImgContainer>
          <Fade bottom duration={1000} delay={200} distance="50px">
            <Img
              fluid={post.frontmatter.img1.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </Fade>
        </ImgContainer>
        <TextIntro>
          <Fade bottom duration={1000} delay={300} distance="50px">
            <div className="titleProject">
              <h1>{post.frontmatter.title}</h1>
              <h2>{post.frontmatter.subtitle}</h2>
              <h3>{post.frontmatter.tag}</h3>
            </div>
            <div className="textProject">
              <p>{post.frontmatter.summary}</p>
              <p>{post.frontmatter.challenge}</p>
            </div>
            <div className="infosProject">
              <div>
                <p>Lead time :</p>
                <p>{post.frontmatter.leadTime}</p>
              </div>
              <div>
                <p>Target type :</p>
                <p>{post.frontmatter.targetType}</p>
              </div>
              <div>
                <p>Project goal :</p>
                <p>{post.frontmatter.projectGoal}</p>
              </div>
              <div>
                <p>Expertises :</p>
                <p>{post.frontmatter.expertises}</p>
              </div>
            </div>
          </Fade>
        </TextIntro>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <ProjectImg>
            <Img
              fluid={post.frontmatter.img2.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </ProjectImg>
        </Fade>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <TextProject>
            <div id="realisations">
              <h4>Realisations</h4>
              <ul>
                {post.frontmatter.realisations.map(realisations => (
                  <li key={realisations}>{realisations}</li>
                ))}
              </ul>
            </div>
            <div id="collaborations">
              <h4>Collaborations</h4>
              <ul>
                {post.frontmatter.collaborations.map(collaborations => (
                  <li key={collaborations}>{collaborations}</li>
                ))}
              </ul>
            </div>
          </TextProject>
        </Fade>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <ProjectImg>
            <Img
              fluid={post.frontmatter.img3.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </ProjectImg>
        </Fade>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <TextProject>
            <div id="experience">
              <h4>Experience</h4>
              <p>{post.frontmatter.experience}</p>
            </div>
            <div id="results">
              <h4>Results</h4>
              <ul>
                {post.frontmatter.results.map(results => (
                  <li key={results}>{results}</li>
                ))}
              </ul>
            </div>
          </TextProject>
        </Fade>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <ProjectImg>
            <Img
              fluid={post.frontmatter.img4.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </ProjectImg>
        </Fade>
      </Container>
      <Footer />
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        tag
        text
        summary
        challenge
        leadTime
        targetType
        projectGoal
        expertises
        experience
        realisations
        collaborations
        results
        tools
        img1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
