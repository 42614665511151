import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ImgContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`;

export const TextIntro = styled.div`
  padding: 10rem 2rem;
  display: block;
  text-align: left;
  vertical-align: top;
  ${MEDIA.PHONE`
    padding: 5rem 0rem;
  `};

  .titleProject {
    vertical-align: top;
    display:inline-block;
    width: 50%;
    ${MEDIA.PHONE`
      display: block;
      width: 100%;
      text-align:center;
      margin-bottom: 3rem;
    `};
  }

  .textProject {
    display:inline-block
    width: 50%;
    ${MEDIA.PHONE`
      display: block;
      width: 100%;
      margin-bottom: 5rem;
    `};
  }
  
  h1 {
    display: block;
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 2rem;
    ${MEDIA.PHONE`
      font-size: 1.6rem;
    `};
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    ${MEDIA.PHONE`
      font-size: 1.2rem;
    `};
  }

  h3 {
    font-size: 1.4rem;
    font-style: italic;
    ${MEDIA.PHONE`
      font-size: 1.2rem;
    `};
  }

  .infosProject {
    width: 50%;
    margin-top: -120px;
    ${MEDIA.PHONE`
      width: 100%;
      margin-top: 0px;
    `};

    div {
      display: block;
      p:nth-child(1) {
        width: 100px;
        margin-right: 20px;
        opacity: 0.5;
        display: inline-block;
        ${MEDIA.PHONE`
          display: block;
          width: auto;
        `};
      }
      p:nth-child(2) {
        display: inline-block;
        ${MEDIA.PHONE`
          display: block;
          width: auto;
          margin-bottom: 5rem;
        `};
      }
    }
  }
`;

export const TextProject = styled.div`
  padding: 10rem 2rem;
  ${MEDIA.PHONE`
    padding: 5rem 0rem;
  `};
`;

export const ProjectImg = styled.div`
  background: var(--bgProject);
  padding: 10rem 10rem;
  ${MEDIA.TABLET`
    padding: 10rem 2rem;
  `};
  ${MEDIA.PHONE`
    padding: 10rem 2rem;
  `};
`;

export const Container = styled.div`
  max-width: 960px;
  margin: 5rem auto;
  ${MEDIA.TABLET`
    display: block;
    padding: 0rem;
  `};
  ${MEDIA.PHONE`
    margin-top: 10rem;
    padding: 0 3rem;
  `};

  p {
    line-height: 2.6rem;
    font-weight: 300;
  }

  li {
    line-height: 2.6rem;
    font-weight: 300;
  }

  h4 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 3rem;
    ${MEDIA.PHONE`
      font-size: 1.8rem;
    `};
  }

  ul {
    list-style: outside;
    position: relative;
    left: 20px;

    li {
      margin-bottom: 2rem;
      ${MEDIA.PHONE`
        padding-right: 20px;
      `};
    }
  }

  #realisations {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    ${MEDIA.PHONE`
      display: block;
      vertical-align: top;
      margin-left: 0;
      width: 100%;
      margin-bottom: 10rem;
    `};
  }

  #collaborations {
    display: inline-block;
    vertical-align: top;
    margin-left: 5%;
    width: 45%;
    ${MEDIA.PHONE`
      display: block;
      vertical-align: top;
      margin-left: 0;
      width: 100%;
    `};
  }

  #experience {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    ${MEDIA.PHONE`
      display: block;
      vertical-align: top;
      margin-left: 0;
      width: 100%;
    `};
    p {
      padding-right: 10rem;
      ${MEDIA.PHONE`
      padding-right: 0rem;
    `};
    }
  }

  #results {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    ${MEDIA.PHONE`
      display: block;
      vertical-align: top;
      margin-left: 0;
      width: 100%;
    `};
  }
`;
